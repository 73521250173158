import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { supabase } from '../supabaseClient';
import Header from './Header';
import Footer from '../Components/Common/Footer';

// Define light and dark themes
const lightTheme = {
  cardBackground: "#ffffff",
  color: "#000000",
  background: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: "#ffffff",
  cardBackground: "rgb(55 65 81)",
  cardColor: "#ffffff",
  borderColor: "#ffffff",
};

// Global styles
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    height: 100%;
    transition: background-color 0.3s, color 0.3s;
  }
`;

// Main container
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  
  overflow-y: auto;
  margin: 100px 10% 5% 10%;
`;

const CardContainer = styled.div`
  display: grid;
  // grid-template-columns: repeat(2, 1fr); // two columns for a row
  gap: 10px;
  padding: 20px;
  background: ${({ theme }) => theme.cardBackground};
  height: 400px;
  margin-top: 5px;
  overflow-y: auto;
`;


const Card = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 10px;
  padding: 15px;
  font-size: 14px;
  height: 400px;
  overflow-y: auto;
    // width: ${({ width }) => width || '100%'};
`;
const FullWidthCard = styled(Card)`
  grid-column: span 2; // spans across both columns
`;


const SearchBar = styled.input`
  width: 15%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  margin-bottom: 20px;
  outline: none;
  font-size: 16px;
`;

const SuggestionList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 40px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  background: ${({ theme }) => theme.cardBackground};
  width: 30%;
  position: absolute; // Absolute position to overlay suggestions
  z-index: 1;
  max-height: 200px; // Limit height
  overflow-y: auto; // Enable scrolling
`;

// Individual suggestion item
const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: #7ebdc2; // Highlight on hover
  }
`;


const CardContent = styled.p`
  margin: 10px;  
  
`;

const CardContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding: 15px;
  background: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.cardColor};
  // border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  height: 195px;
  overflow-y:auto;
`;

const Column = styled.div`
  flex: 1; // Each column takes equal space
  margin: 1px; // Margin between columns
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px; // Adds consistent spacing between columns
`;


const SocioEconomic = ({ country = 'United States' }) => {
  const [cardData, setCardData] = useState({
    population: [],
    climate: [],
    ethnicGroups: [],
    urbanAreas: [],
    urbanPopulation: [],
    birthStats: [],
    lifeExpectancy: [],
    education: [],
    health: [],
    media: []
  });
  const [searchQuery, setSearchQuery] = useState(localStorage.getItem('country') || 'United States');
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [valueObj, setValueObj] = useState({});
  const [lastUpdated, setLastUpdated] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('country') || 'United States'); // Default to USA (ISO code)


  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme;
    }
  });

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data, error } = await supabase.rpc('get_distinct_countries_with_values');
        if (error) {
          console.error("Error fetching countries:", error);
          return;
        }
        setCountries(data);
        setFilteredCountries(data);
        const countriesObject = data.reduce((acc, country) => {
          acc[country.country] = country.value; // Assuming country.value contains the value you want to store
          return acc;
        }, {});
        setValueObj(countriesObject);

        const lastUpdated = await supabase
        .from('CIA_Factbook')
        .select('LastUpdated', { count: 'exact' })
        .order('LastUpdated', { ascending: false })
        .limit(1);

        setLastUpdated(lastUpdated?.data[0].LastUpdated);
        setFilteredCountries([]);

      } catch (err) {
        console.error('Error:', err);
      }
    };

    fetchCountries();
  }, []);

  const handleCountryClick = (country,code) => {

    setFilteredCountries([]);
    setSelectedCountry(country); 
    
    
    
    // setCountryValue(valueObj[country]); 
    
    setSearchQuery(country); 
    setFilteredCountries([]);
    
    

  };

  useEffect(() => {

    const fetchData = async () => {
        setFilteredCountries([]);

        
      try {
        const queries = [
          { key: 'population', variables: ['Population - Total', 'Population - Male', 'Population - Female', 'Population Distribution', 'Population Growth Rate'] },
          { key: 'climate', variables: ['Climate', 'Climate Note', 'Natural Hazards'] },
          { key: 'ethnicGroups', variables: ['Ethnic Groups', 'Ethnic Groups Notes', 'Nationality', 'Languages', 'Languages Note', 'Religions'] },
          { key: 'urbanAreas', variables: ['Major Urban Areas', 'Major Watersheds', 'Major Aquifers', 'Total Water Withdrawal - Municipal', 'Total Water Withdrawal - Industrial', 'Total Water Withdrawal - Agricultural'] },
          { key: 'urbanPopulation', variables: ['Urban Population', 'Rate of Urbanization', 'Net Migration Rate', 'Electricity Access - Urban Areas', 'Electricity Access - Rural Areas', 'Internet Users'] },
          { key: 'birthStats', variables: ['Population Growth Rate', 'Birth Rate', 'Infant Mortality Rate – Total', 'Infant Mortality Rate – Male', 'Infant Mortality Rate – Female', 'Maternal Mortality Ratio', "Mother's Mean Age at First Birth"] },
          { key: 'lifeExpectancy', variables: ['Death Rate', 'Life Expectancy At Birth - Total', 'Life Expectancy At Birth - Male', 'Life Expectancy At Birth - Female', 
            'Total Dependency Ratio', 'Youth Dependency Ratio', 'Elderly Dependency Ratio'] },
          { key: 'education', variables: ['School Life Expectancy (Primary To Tertiary Education) – Total', 'School Life Expectancy (Primary To Tertiary Education) – Male', 'School Life Expectancy (Primary To Tertiary Education) – Female'] },
          { key: 'health', variables: ['Obesity - Adult Prevalence Rate', 
'Alcohol Consumption per Capita Total', 
'Tobacco Use - Total'] },
          { key: 'media', variables: ['Broadcast Media', 'Telecommunication Systems General Assessment', 'Telecommunication Systems Domestic', 'Telecommunication Systems International'] },
        ];

        const data = {};

        for (let query of queries) {
          const { data: result } = await supabase
            .from('CIA_Factbook')
            .select('Variable, Value')
            .eq('Country', selectedCountry)
            .in('Variable', query.variables)
            .order('created_at', { ascending: false });

          data[query.key] = result;
        }

        setCardData(data);
        setFilteredCountries([]);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, [selectedCountry]);

  const renderCard = (title, data, width) => (
    <Card width={width}>
      {/* <Title>{title}</Title> */}
      {(data || []).map((item, index) => {
         const totalWaterWithdrawal = (Array.isArray(data[0]) ? data[0] : [])
         .concat(Array.isArray(data[1]) ? data[1] : [])
         .find(item => item.Variable === 'Total Water Withdrawal');
        const value = item.Value;
        
        // Skip invalid values
        if (value === 'NaN' || value === 'nan' || value === 'Nan' || value == null) {
          return null;
        }
        return (
          <p key={index}>            
        {totalWaterWithdrawal && (
                <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                Total Water Withdrawal: Municipal: {splitTextIntoParagraphs(totalWaterWithdrawal.Municipal || 'N/A')}, 
                Industrial: {splitTextIntoParagraphs(totalWaterWithdrawal.Industrial || 'N/A')}, 
                Agricultural: {splitTextIntoParagraphs(totalWaterWithdrawal.Agricultural || 'N/A')}
                </div>
            )}
             <strong style={{fontWeight: 'bold'}}>{item.Variable}:</strong> 
             {splitTextIntoParagraphs(value) || 'No data available'}
          </p>
        );
      })}
    </Card>
  );
  useEffect(() => {
    const result = countries.filter(country =>
      country.country.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCountries(result);
    
  }, [searchQuery, countries]);

  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  const splitTextIntoParagraphs = (text) => {
    const sentences = text.split('.');
    let paragraphArray = [];
    let paragraph = '';
    
    sentences.forEach((sentence, index) => {
      if (sentence.trim()) {
        // Rebuild the sentence with the period
        paragraph += sentence.trim() + '.';
  
        // If 4 full stops have been added or it's the last sentence
        if ((index + 1) % 4 === 0 || index === sentences.length - 1) {
          // Capitalize the first letter of the paragraph
          paragraph = paragraph.charAt(0).toUpperCase() + paragraph.slice(1).trim();
          paragraphArray.push(paragraph);
          paragraph = ''; // Reset paragraph
        }
      }
    });
  
    return paragraphArray.map((para, idx) => <p key={idx}>{para}</p>); // Return as JSX <p> tags
  }

return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />

<SearchBar
          type="text"
          placeholder="Search for a country"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {/* Suggestion List for matching countries */}
        {searchQuery && filteredCountries.length > 0 && (
          <SuggestionList>
            {filteredCountries.map((country) => (
              <SuggestionItem key={country.country} onClick={() => handleCountryClick(country.country)}>
                {country.country}
              </SuggestionItem>
            ))}
          </SuggestionList>
        )}

  
        <Row>
          <Column>
            <CardContainer>                          
              <CardContent>
              {cardData?.population?.length > 0 ? (
  <div>
    {/* Display Population section as a combined block */}
    <div style={{ fontWeight: 'bold', marginTop: '8px' }}>Population:</div>
    {['Population - Total', 'Population - Male', 'Population - Female', 'Population Distribution', 'Population Growth Rate']
      .map(variable => cardData.population.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
      .filter(Boolean) // Filter out undefined items
      .map((item, index) => (
        <div key={index} style={{ marginLeft: '16px' }}>
          <strong style={{ fontWeight: 'bold'}}>{item.Variable.replace('Population -','')}:</strong>
          <p>{item.Value}</p>
        </div>
      ))}
  </div>
) : (
  'No data available'
)}

  

                </CardContent>              
            </CardContainer>            
          </Column>          
          <Column>
            <CardContainer>                          
              <CardContent>
              {cardData.climate.length > 0 ? (
  <div>
    {/* Display Climate section */}
    {['Climate', 'Climate Note', 'Natural Hazards']
      .map(variable => cardData.climate.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
      .filter(Boolean) // Filter out undefined items
      .map((item, index) => (
        <div key={index}>
          <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong>
          <p>{item.Value}</p>
        </div>
      ))}
  </div>
) : (
  'No data available'
)}

                </CardContent>              
            </CardContainer>            
          </Column>          
        </Row>


        <Row>
          <Column>
            <CardContainer>                          
              <CardContent>
              {cardData.ethnicGroups.length > 0 ? (
  <div>
    {/* Display ordered items */}
    {['Ethnic Groups', 'Ethnic Groups Notes', 'Nationality', 'Languages', 'Languages Note', 'Religions']
      .map(variable => cardData.ethnicGroups.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
      .filter(Boolean) // Remove undefined items
      .map((item, index) => (
        <div key={index}>
          <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong>
          <p>{item.Value}</p>
        </div>
      ))}
  </div>
) : (
  'No data available'
)}

                </CardContent>              
            </CardContainer>            
          </Column>          
        </Row>


        <Row>
          <Column>
            <CardContainer>                          
              <CardContent>
              {cardData.urbanAreas.length > 0 ? (
  <div>
    {['Major Urban Areas', 'Major Watersheds', 'Major Aquifers']
      .map(variable => cardData.urbanAreas.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
      .filter(Boolean) // Filter out undefined items
      .map((item, index) => (
        <div key={index}>
          <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong>
          <p>{item.Value}</p>
        </div>
      ))}
      
    {/* Combine Total Water Withdrawal under a single heading */}
    {['Total Water Withdrawal - Municipal', 'Total Water Withdrawal - Industrial', 'Total Water Withdrawal - Agricultural']
      .map(variable => cardData.urbanAreas.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
      .filter(Boolean) // Filter out undefined items
      .length > 0 && (
        <div style={{ marginTop: '16px' }}>
          <strong style={{ fontWeight: 'bold' }}>Total Water Withdrawal:</strong><br/><br/>
          {['Total Water Withdrawal - Municipal', 'Total Water Withdrawal - Industrial', 'Total Water Withdrawal - Agricultural']
            .map(variable => cardData.urbanAreas.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
            .filter(Boolean)
            .map((item, index) => (
              <div key={index} style={{ marginLeft: '16px' }}>
                <strong style={{ fontWeight: 'bold' }}>{item.Variable.replace('Total Water Withdrawal - ', '')}:</strong> {item.Value}<br/><br/>
                    </div>
                  ))}
              </div>
            )}
        </div>
      ) : (
        'No data available'
      )}

                </CardContent>              
            </CardContainer>            
          </Column>          
          <Column>
            <CardContainer>                          
              <CardContent>
              {cardData.urbanPopulation.length > 0 ? (
  <div>
    {/* Display ordered items */}
    {['Urban Population', 'Rate of Urbanization', 'Net Migration Rate', 'Electricity Access - Urban Areas', 'Electricity Access - Rural Areas', 'Internet Users']
      .map(variable => cardData.urbanPopulation.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
      .filter(Boolean) // Filter out undefined items
      .map((item, index) => {
        // Handle Electricity Access variables separately
        if (item.Variable === 'Electricity Access - Urban Areas' || item.Variable === 'Electricity Access - Rural Areas') {
          return null; // Skip adding here; handled separately
        }
        return (
          <div key={index}>
            <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong>
            <p>{item.Value}</p>
          </div>
        );
      })}

    {/* Combine Electricity Access under a single heading */}
    {['Electricity Access - Urban Areas', 'Electricity Access - Rural Areas']
      .map(variable => cardData.urbanPopulation.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
      .filter(Boolean)
      .length > 0 && (
        <div style={{ marginTop: '16px' }}>
          <strong style={{ fontWeight: 'bold' }}>Electricity Access:</strong><br/><br/>
          {['Electricity Access - Urban Areas', 'Electricity Access - Rural Areas']
            .map(variable => cardData.urbanPopulation.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
            .filter(Boolean)
            .map((item, index) => (
              <div key={index} style={{ marginLeft: '16px' }}>
                <strong  style={{ fontWeight: 'bold' }}>{item.Variable.replace('Electricity Access - ', '')}:</strong> {item.Value}<br/><br/>
              </div>
            ))}
        </div>
      )}
        </div>
      ) : (
        'No data available'
      )}

                </CardContent>              
            </CardContainer>            
          </Column>          
        </Row>


        <Row>
          <Column>
            <CardContainer>                          
              <CardContent>
                {cardData.birthStats.length > 0 ? (
                    cardData.birthStats.map((item, index) => (
                    <div key={index}>
                        <strong style={{fontWeight: 'bold'}}>{item.Variable}: </strong><p> {item.Value}</p>
                    </div>
                    ))
                ) : (
                    'No data available'
                )}
                </CardContent>              
            </CardContainer>            
          </Column>          
          <Column>
            <CardContainer>                          
              <CardContent>
                {[].length > 0 ? (
                    [].map((item, index) => (
                    <div key={index}>
                        <strong style={{fontWeight: 'bold'}}>{item.Variable}: </strong><p> {item.Value}</p>
                    </div>
                    ))
                ) : (
                    'No data available'
                )}
                </CardContent>              
            </CardContainer>            
          </Column>          
        </Row>
        <Row>
      <Column>
        <CardContainer1>
          <CardContent>
          {cardData.lifeExpectancy.length > 0 ? (
  <div>
    {/* Display ordered items */}
    {['Death Rate', 'Dependency Ratios – Total', 'Dependency Ratios – Youth', 'Dependency Ratios – Elderly']
      .map(variable => cardData.lifeExpectancy.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
      .filter(Boolean) // Filter out undefined items
      .map((item, index) => (
        <div key={index}>
          <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong>
          <p>{item.Value}</p>
        </div>
      ))}

    {/* Combine Life Expectancy at Birth under a single heading */}
    {['Life Expectancy at Birth – Total', 'Life Expectancy at Birth – Male', 'Life Expectancy at Birth – Female']
      .map(variable => cardData.lifeExpectancy.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
      .filter(Boolean)
      .length > 0 && (
        <div style={{ marginTop: '16px' }}>
          <strong style={{ fontWeight: 'bold' }}>Life Expectancy at Birth:</strong><br/><br/>
          {['Life Expectancy at Birth – Total', 'Life Expectancy at Birth – Male', 'Life Expectancy at Birth – Female']
            .map(variable => cardData.lifeExpectancy.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
            .filter(Boolean)
            .map((item, index) => (
              <div key={index} style={{ marginLeft: '16px' }}>
                <strong style={{ fontWeight: 'bold' }}>{item.Variable.replace('Life Expectancy at Birth – ', '')}:</strong> {item.Value}<br/><br/>
              </div>
            ))}
        </div>
      )}
  </div>
) : (
  'No data available'
)}

          </CardContent>
        </CardContainer1>
        <CardContainer1>
          <CardContent>
            {cardData.education.length > 0 ? (
              cardData.education.map((item, index) => (
                <div key={index}>
                  <strong style={{ fontWeight: 'bold' }}>{item.Variable}: </strong>
                  <p>{item.Value}</p>
                </div>
              ))
            ) : (
              'No data available'
            )}
          </CardContent>
        </CardContainer1>
      </Column>

      <Column>
        <CardContainer>
          <CardContent>
            {cardData.health.length > 0 ? (
              cardData.health.map((item, index) => (
                <div key={index}>
                  <strong style={{ fontWeight: 'bold' }}>{item.Variable}: </strong>
                  <p>{item.Value}</p>
                </div>
              ))
            ) : (
              'No data available'
            )}
          </CardContent>
        </CardContainer>
      </Column>
    </Row>

    <Row>
          <Column>
            <CardContainer>                          
              <CardContent>
                {cardData.media.length > 0 ? (
                    cardData.media.map((item, index) => (
                    <div key={index}>
                        <strong style={{fontWeight: 'bold'}}>{item.Variable}: </strong><p> {item.Value}</p>
                    </div>
                    ))
                ) : (
                    'No data available'
                )}
                </CardContent>              
            </CardContainer>            
          </Column>                    
        </Row>
      </Container>
      <Footer />

    </ThemeProvider>
  );
};

export default SocioEconomic;
