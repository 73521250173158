import React, { useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { supabase } from '../supabaseClient';

const UserSync = () => {    
  const { user } = useUser();
  useEffect(() => {
    const syncUserWithSupabase = async () => {        
        
      if (!user) return;

      const { id, emailAddresses, firstName, lastName } = user;

      // Upsert the user in Supabase
      const { error } = await supabase
        .from('vegaveta_users')
        .upsert({
          clerk_id: id,
          email: emailAddresses[0].emailAddress,
          first_name: firstName,
          last_name: lastName,
        });

      if (error) {
        console.error('Error syncing user with Supabase:', error);
      }
    };

    syncUserWithSupabase();
  }, [user]);

  return null;
};

export default UserSync;
