import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../../pages/Header/index'; // Ensure this path is correct
import Footer from '../../Components/Common/Footer';
import serviceImage from '../../assets/images/vegaveta/service_main.jpg';
import backGround from '../../assets/images/vegaveta/bg.jpg';
import serviceImage1 from '../../assets/images/vegaveta/economic1.jpg';
import serviceImage2 from '../../assets/images/vegaveta/economic2.jpg';

const lightTheme = {
  // background: "#ffffff",
  color: "#000000",
  // cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
  titleColor: "#AC544C",
  textColor: "#333333",
  backgroundImage: `#fff`
};

const darkTheme = {
  // background: "#ffffff",
  color: "#000000",
  // cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
  titleColor: "#AC544C",
  textColor: "#333333",
  backgroundImage: `#fff`
};

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.backgroundImage};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
  }
`;


const Card = styled.div`

  display: flex;
backgroundImage: backGround,
  color: ${({ theme }) => theme.cardColor};
  // border-radius: 8px;
  padding: 0;
  margin-top:200px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  // border: 1px solid ${({ theme }) => theme.cardColor};

  @media (max-width: 1000px) {
    flex-direction: column;
    width: calc(100% - 20px);
    height: fit-content;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    flex-direction: column;
    width: calc(100% - 20px);
    height: fit-content;
  }
`;

const CardImage = styled.img`
  width: 45%;
  height: 600px;
  object-fit: cover;
  margin:50px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  text-align: center;
  width: 55%;
  font-size: 1.5em;
`;
const CardTitle = styled.h3`
  font-size: 1.8em;
  color: ${({ theme }) => theme.color};
  font-weight: bold;
  font-family: 'Lora', serif;
`;

const Subref2 = styled.div`
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center columns horizontally */
  align-items: center; /* Center content vertically */
  gap: 20px; /* Space between columns */
  font-size: 2em;
  margin: 70px auto; /* Center horizontally and add vertical spacing */
  padding: 50px 200px;
  color: ${({ theme }) => theme.color};
  background: #ac544c;
  width: 100%; /* Adjust as needed for responsive layout */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack columns vertically on small screens */
    width: 100%;
    font-size: 1.2em;
    margin: 20px auto;
    padding: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically within each column */
  align-items: center; /* Center horizontally within the column */
  text-align: center;
  width:40%;
`;
const Column1 = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically within each column */
  align-items: center; /* Center horizontally within the column */
  text-align: center;
  width:60%;
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
//   margin-bottom: 20px; /* Add spacing between text and button */
`;

const StyledButton = styled.button`
  display: flex;
  background-color: ${({ theme }) => theme.color};
  color: #fff;
  border: none;
  padding: 20px 60px;
  width:30%;
  border-radius: 50px; /* Adjust for full rounding */
  cursor: pointer;
  font-size: 0.7em;
  justify-content: center;
  align-items: center;

  &:hover {
    // background-color: ${({ theme }) => theme.buttonHoverBackground};
  }`;


const EconomicData = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });

  

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  //   setTheme(mediaQuery.matches ? darkTheme : lightTheme);
  //   const handleChange = (e) => setTheme(e.matches ? darkTheme : lightTheme);
  //   mediaQuery.addEventListener("change", handleChange);
  //   return () => mediaQuery.removeEventListener("change", handleChange);
  // }, []);

  

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />

        <Card>
            <CardContent>
              <CardTitle>Premier Economic Data Service</CardTitle>
              <p> Covering GDP growth rates, inflation, employment statistics, and trade balances, we provide a comprehensive view of the global economic landscape.</p>
            </CardContent>
            <CardImage src={serviceImage1} alt="Image 1" />
          </Card>

          <Card>
          <CardImage src={serviceImage2} alt="Image 1" />
            <CardContent>
              <CardTitle>Strategic Market Insights</CardTitle>
              <p> Designed for investors and analysts, our service helps you understand the nuances of the macroeconomic environment and its impact on investment opportunities and risk assessment. Our data and analysis support strategic, informed investment decisions by revealing broader market dynamics, ensuring you stay ahead in the evolving economic landscape.</p>
            </CardContent>
            
          </Card>              
      <Subref2>
        <Column>
            Schedule a consultation with a VegaVeta Expert
            </Column>
            <Column1>
            <StyledButton>Free Demo</StyledButton>
            </Column1>
          </Subref2>
      <Footer />
    </ThemeProvider>
  );
};

export default EconomicData;
