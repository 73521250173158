import React, { useEffect, useState } from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
// import bullBearImage from "./bull-bear.png";
import bullBearImage from '../../assets/images/vegaveta/main.jpg';
import dynamicModel from "./dynamicModel-removebg.png";
import NewsSection from "../../Components/Common/NewsSection";
import Footer from "../../Components/Common/Footer";
import Header from "../Header";
import serviceImage4 from '../../assets/images/vegaveta/9.jpg';
import serviceImage2 from '../../assets/images/vegaveta//5.jpg';
import serviceImage1 from '../../assets/images/vegaveta/service3.jpg';
import serviceImage6 from '../../assets/images/vegaveta/6.jpg';
import serviceImage7 from '../../assets/images/vegaveta/3.jpg';
import serviceImage8 from '../../assets/images/vegaveta/8.jpg';
import serviceImage9 from '../../assets/images/vegaveta/2.jpg';
import serviceImage3 from '../../assets/images/vegaveta/7.jpg';
import serviceImage5 from '../../assets/images/vegaveta/4.jpg';
import backGround from '../../assets/images/vegaveta/bg.jpg';

// Define your light and dark themes
const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${({ theme }) => theme.backgroundImage});
    background-repeat: repeat; /* Ensures the image repeats */
    background-position: top left; /* Start repeating from the top-left */
    // background-size: auto; /* Prevents stretching (default behavior) */
    // background-color: ${({ theme }) => theme.backgroundColor}; /* Fallback background color */
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
  }
`;

const lightTheme = {
  backgroundImage: backGround, // Use imported image directly
  backgroundColor: "#f4f4f4",
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#666',
};

const darkTheme = {
  backgroundImage: backGround, // Use imported image directly
  backgroundColor: "#f4f4f4",
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#666',
};

// Styled components using theme properties
const Container = styled.div`
  text-align: center;
  // padding: 50px;
  font-family: 'Lora', serif;
  background-image: ${({ theme }) => theme.background};
  background-repeat: ${({ theme }) => theme.backgroundRepeat};
  background-size: ${({ theme }) => theme.backgroundSize};
  background-position: ${({ theme }) => theme.backgroundPosition};
  // color: ${({ theme }) => theme.color};
  min-height: 100vh; /* Ensure it covers the full height */
  width: 100%;
`;

const Main = styled.main`
  padding-top: 20px;
`;

const Title = styled.h1`
  font-size: 3em;
  color: ${({ theme }) => theme.color};
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.8em;
  color: ${({ theme }) => theme.color};
  font-weight: bold;
  font-family: 'Lora', serif;
`;

const CardTitle1 = styled.h3`
  font-size: 2.8em;
  color: ${({ theme }) => theme.color};
  font-weight: bold;
  font-family: 'Lora', serif;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 1.5em;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.color};
  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const SubTitleref = styled.p`
  font-size: 2.5em;
  margin: 70px 0;
  padding: 10px;
  color: ${({ theme }) => theme.color};
  @media (max-width: 768px) {
    font-size: 2em;
    margin: 40px 0;
  }
`;

const Subref = styled.p`
  font-size: 2em;
  margin: 0px 0;
  padding: 100px 200px 10px 200px;
  color: ${({ theme }) => theme.color};
  // background: linear-gradient(90deg, rgba(128,128,128,1) 0%, rgba(255,0,0,0.7) 100%);
  //       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.2em;
    margin: 20px 0;
  }
`;
const Subref3 = styled.h3`
  font-size: 4em;
  margin: 0px 0;
  padding: 100px 200px 10px 200px;
  color: ${({ theme }) => theme.color};
  // background: linear-gradient(90deg, rgba(128,128,128,1) 0%, rgba(255,0,0,0.7) 100%);
  //       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  font-weight:bold;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.2em;
    margin: 20px 0;
  }
`;

const Subref1 = styled.p`
display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically if needed */
  font-size: 2em;
  margin: 70px 0;
  padding: 100px 200px 100px 200px;
  color: ${({ theme }) => theme.color};
  background: linear-gradient(90deg, #8e8e8e 25%, #AC544C 100%);

  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.2em;
    margin: 20px 0;
  }
`;

const Subref4 = styled.p`
display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically if needed */
  font-size: 1.5em;
  margin: 70px 0;
  padding: 10px 200px 40px 200px;
  color: ${({ theme }) => theme.color};
  background: linear-gradient(90deg, #8e8e8e 25%, #AC544C 100%);

  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.5em;
    margin: 20px 0;
  }
`;
const Subref5 = styled.p`
display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically if needed */
  font-size: 2em;
  // margin-bottom: 150px;
  padding: 100px 200px 10px 200px;
  color: ${({ theme }) => theme.color};
  // background: linear-gradient(90deg, rgba(128,128,128,1) 0%, rgba(255,0,0,0.7) 100%);
  //       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.2em;
    margin: 20px 0;
  }
`;

const Subref2 = styled.p`
display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically if needed */
  font-size: 2em;
  margin: 70px 0;
  padding: 50px 200px 50px 200px;
  color: ${({ theme }) => theme.color};
  background: linear-gradient(90deg, #8e8e8e 25%, #AC544C 100%);

  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.2em;
    margin: 20px 0;
  }
`;

const Image = styled.img`
  width: 100%;
  // max-width: 1400px;
  margin-top: 30px;
`;

const Image1 = styled.img`
  width: 100%;
  max-width: 700px;
  // margin-top: 10px;
`;

const Image2 = styled.img`
  width: 100%;
  max-width: 900px;
  // margin-top: 10px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 40px auto;
  width: 90%;
`;

const Card = styled.div`

  display: flex;
backgroundImage: backGround,
  color: ${({ theme }) => theme.cardColor};
  // border-radius: 8px;
  padding: 0;
  margin-top:20px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  // border: 1px solid ${({ theme }) => theme.cardColor};

  @media (max-width: 1000px) {
    flex-direction: column;
    width: calc(100% - 20px);
    height: fit-content;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    flex-direction: column;
    width: calc(100% - 20px);
    height: fit-content;
  }
`;

const CardImage = styled.img`
  width: 45%;
  height: 600px;
  object-fit: cover;
  
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  text-align: center;
  width: 55%;
  font-size: 1.5em;
`;
const CardContent1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  text-align: center;
  width: 100%;
  font-size: 1.2em;
`;
const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.color};
  color: #fff;
  border: none;
  padding: 20px 100px 20px 100px;
  border-radius: 50px; /* Adjust for full rounding */
  cursor: pointer;
  margin-top: 50px;
  font-family: 'Lora', serif;
  p {font-size:1.1em;}
width: 700px;
  &:hover {
    // background-color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;

const StyledButton1 = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.background};
  border: none;
  padding: 20px 100px 20px 100px;
  border-radius: 50px; /* Adjust for full rounding */
  cursor: pointer;
  margin-top: 50px;
  font-size:1.5em;
  width: 700px;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;

const CardImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.background};
`;

const CardImage1 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CardImageOverlay = styled.div`
  position: absolute;
  top: 100px;
  left: 60px;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  // background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  color: #000; /* Text color */
  text-align: center;
  padding: 10px;
 
  font-weight: bold;
  width:60%;
      font-size: 2.5em;


  h3 {
    margin: 0;
    font-size: 2.5em;
  }

  p {
    margin: 10px 0 0;
    font-weight: bold;
          font-size: 1.2em;

  // width:50%;
  }
`;

const Homepage = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      console.log(cleanTheme);
      
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });
  // const [theme, setTheme] = useState(lightTheme);

  console.log(theme);
  
  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  //   if (mediaQuery.matches) {
  //     setTheme(darkTheme);
  //   } else {
  //     setTheme(lightTheme);
  //   }

  //   const handleChange = (e) => {
  //     setTheme(e.matches ? darkTheme : lightTheme);
  //   };
  //   mediaQuery.addEventListener("change", handleChange);

  //   return () => {
  //     mediaQuery.removeEventListener("change", handleChange);
  //   };
  // }, []);

  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />
        <Main>
        {/* <Title>Unlock the Future of Finance with VegaVeta's AI-Driven Analytics</Title> */}
          {/* <Title>Welcome to VegaVeta</Title>
          <Subtitle>Unlock the Future of Finance with AI-Driven Analytics</Subtitle> */}
          
          <Card>          
          <CardImageContainer>
            <CardImage1 src={bullBearImage} alt="Image 1" />
            <CardImageOverlay>
            <p>Comprehensive financial data, powerful</p><p>analytics, and intuitive portfolio</p> <p> management solutions tailored to your</p><p> investments needs</p>
            </CardImageOverlay>
          </CardImageContainer>
        </Card>

          <Subref1>
            At VegaVeta, we equip investors with advanced tools and expert insights to manage their stock portfolios effectively. Our platform provides comprehensive financial data, powerful analytics, and intuitive portfolio management solutions tailored to your investment needs. The clear, easy-to-use user interface of our dashboard ensures a seamless experience for all users.
            <StyledButton>Start your free trial</StyledButton>
          </Subref1>
          {/* <StyledButton>Schedule a Consultation</StyledButton> */}
          {/* <SubTitleref>Why VegaVeta?</SubTitleref>
          <Subref>Quinbridge Dynamic Model</Subref> */}
          <Subref3>Our Unique Advantage</Subref3>
          <Image1 src={dynamicModel} alt="Quinbridge Dynamic Model" />
          <Subref>
            The QuinBridge Dynamic Model is a cornerstone of our analytical framework, designed to integrate and synthesize information from multiple dimensions to deliver a holistic understanding of the financial landscape.
          </Subref>
          <StyledButton1>Learn more about the Quinbridge model</StyledButton1>
          <Subref2>
            See how VegaVeta can help your management firm reach new and exciting goals for your clients.<br/>
            <StyledButton>Start your free trial</StyledButton>
          </Subref2>
          {/* <StyledButton>Start your free trial</StyledButton> */}
          {/* <CardContainer> */}
          <Card>
            <CardContent>
              <CardTitle1>Discover the ways VegaVeta drives you and your clients toward new success</CardTitle1>
              {/* <p>Access real-time and historical data on thousands of stocks, ETFs, and indices. Our robust analysis helps you make informed investment decisions, keeping you ahead of market trends.</p> */}
            </CardContent>
            {/* <CardImage src={serviceImage1} alt="Image 1" /> */}
          </Card>
          <Card>
            <CardContent>
              <CardTitle>Advanced Financial Data</CardTitle>
              <p>Access real-time and historical data on thousands of stocks, ETFs, and indices. Our robust analysis helps you make informed investment decisions, keeping you ahead of market trends.</p>
            </CardContent>
            <CardImage src={serviceImage1} alt="Image 1" />
          </Card>
            <Card>
              <CardImage src={serviceImage9} alt="Image 9" />
              <CardContent>
                <CardTitle>Dynamic Charting Tools</CardTitle>
                <p>Visualize market trends and performance with our interactive charting tools. These visualizations help identify patterns and opportunities, optimizing your portfolio strategy.</p>
              </CardContent>
            </Card>
            <Card>
              
              <CardContent>
                <CardTitle>Customizable Dashboards</CardTitle>
                <p>Tailor your dashboard to the metrics that matter most. Monitor portfolio performance, asset allocation, and market news all in one place for a comprehensive view of your investments.</p>
              </CardContent>
              <CardImage src={serviceImage2} alt="Image 2" />
            </Card>
          {/* </CardContainer> */}
          {/* <CardContainer> */}
          <Card>
              <CardImage src={serviceImage7} alt="Image 7" />
              <CardContent>
                <CardTitle>Premier Economic Data</CardTitle>
                <p>Gain insights into macroeconomic indicators, trends, and forecasts with our economic data, covering GDP growth rates, inflation, employment statistics, and trade balances.</p>
              </CardContent>
            </Card>
            <Subref4>
            <StyledButton>Request a free consultation</StyledButton>

            {/* The QuinBridge Dynamic Model is a cornerstone of our analytical framework, designed to integrate and synthesize information from multiple dimensions to deliver a holistic understanding of the financial landscape. */}
          </Subref4>
            <Card>
              
              <CardContent>
                <CardTitle>Comprehensive Fundamental Analysis</CardTitle>
                <p>Our platform provides in-depth evaluations of individual companies, focusing on financial health, business models, industry positions, and growth prospects.</p>
              </CardContent>
              <CardImage src={serviceImage3} alt="Image 3" />
            </Card>
            <Card>
            <CardImage src={serviceImage4} alt="Image 4" />
              <CardContent>
              
                <CardTitle>Regional Analysis</CardTitle>
                <p>Our regional analysis services offer deep insights into specific geographic markets. We evaluate economic and social factors influencing regional markets, providing a clear understanding of unique opportunities and risks.</p>
              </CardContent>
              
            </Card>
            <Card>
              
              <CardContent>
                <CardTitle>In-Depth Portfolio Analysis</CardTitle>
                <p>Our portfolio analysis provides a thorough evaluation using advanced analytics like Monte Carlo simulations, Sharpe Ratio assessments, and Value at Risk (VaR) calculations.</p>
              </CardContent>
              <CardImage src={serviceImage5} alt="Image 5" />
            </Card>
          {/* </CardContainer> */}
          {/* <CardContainer> */}
            <Card>
            <CardImage src={serviceImage6} alt="Image 6" />
              <CardContent>
                <CardTitle>Exclusive Market Insights</CardTitle>
                <p>Access our exclusive market insights and research reports covering a wide range of financial metrics and indicators. Our in-depth analysis keeps you informed and ahead of market trends.</p>
              </CardContent>
              
            </Card>
            
            {/* <Card>
              
              <CardContent>
                <CardTitle>Weekly Chartbook</CardTitle>
                <p>Delivered weekly in a convenient PDF format, our Chartbook provides up-to-date charts and graphs for visual analysis of market trends and performance. This tool helps you stay informed and make data-driven investment decisions.</p>
              </CardContent>
              <CardImage src={serviceImage8} alt="Image 8" />
            </Card> */}
          {/* </CardContainer> */}
          <Subref3>Our mission is to simplify intelligent investing.</Subref3>
          <Image2 src={serviceImage8} alt="Quinbridge Dynamic Model" />
          <CardContent1>
                <CardTitle>Weekly Chartbook</CardTitle>
                <p>Delivered weekly in a convenient PDF format, our Chartbook provides up-to-date charts and graphs for visual analysis of market trends and performance. This tool helps you stay informed and make data-driven investment decisions.</p>
              </CardContent1>
          <Subref1>
            We push the boundaries of investment analysis and portfolio management by integrating advanced artificial intelligence technology with deep financial expertise. Our goal is to empower investors with tools and insights that enhance decision-making and investment performance.
          </Subref1>
          <Subref3>Join Us on Your Investment Journey</Subref3>
          <StyledButton1>Schedule a free Consultation</StyledButton1>
          <Subref5>
            With VegaVeta, you enhance your competitive advantage and position yourself for the future with advanced real-time software, growing your business and offering exceptional service.
          </Subref5>
        </Main>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default Homepage;
