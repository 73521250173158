import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../../pages/Header/index'; // Adjust path based on your project structure
// import headerImage from '../../assets/images/vegaveta/header-image.jpg'; // Adjust the path based on your project structure
import headerImage from '../../assets/images/vegaveta/pricing.jpg'; // Adjust the path based on your project structure
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import Footer from '../../Components/Common/Footer';
import backGround from '../../assets/images/vegaveta/bg.jpg';
const lightTheme = {
  backgroundImage: `url(${backGround})`,
  color: "#000000",
  // cardBackground: "#f5f5f5",
  // cardColor: "#000000",
  borderColor: "#000000",
  buttonBackground: "#4CAF50",
  buttonColor: "#ffffff",
  discountColor: "#FF5733",
};

const darkTheme = {
  backgroundImage: `url(${backGround})`,
  color: "#000000",
  // cardBackground: "#f5f5f5",
  // cardColor: "#000000",
  borderColor: "#000000",
  buttonBackground: "#4CAF50",
  buttonColor: "#ffffff",
  discountColor: "#FF5733",
};

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.backgroundImage};
    color: ${({ theme }) => theme.buttonColor};
    transition: background-color 0.3s, color 0.3s;
  }
`;

const Container = styled.div`
  background: ${({ theme }) => theme.backgroundImage};
  color: ${({ theme }) => theme.color};
  padding-top: 50px;
  text-align: left;
  margin-top: 70px;
`;

const PricingContainer = styled.div`
background: ${({ theme }) => theme.backgroundImage};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const PricingCard = styled.div`
  // background-color: ${({ theme }) => theme.cardBackground};
  background-color: #fff;
  color: ${({ theme }) => theme.cardColor};
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
  text-align: center;
  margin-top:50px;
  gap:10px;
  font-size:1.5em;
`;

const PricingHeader = styled.div`
  // margin-bottom: 10px;
  height:6%;
`;

const Title = styled.h2`
  font-size: 1.5em;
  font-family: 'Lora', serif;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.cardColor};
`;

const Discount = styled.span`
  color: ${({ theme }) => theme.discountColor};
  font-size: 1em;
  display: block;
  margin-bottom: 10px;
`;

const Price = styled.p`
  font-size: 1.2em;
  margin-bottom: 10px;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.buttonColor};
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    opacity: 0.8;
  }
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
`;

const FeatureItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  i {
    margin-right: 10px;
  }
`;

const Card = styled.div`
  display: flex;
  // background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.cardColor};
  // border-radius: 8px;
  padding: 0;
  margin-top:20px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  // border: 1px solid ${({ theme }) => theme.cardColor};

  @media (max-width: 1000px) {
    flex-direction: column;
    width: calc(100% - 20px);
    height: fit-content;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    flex-direction: column;
    width: calc(100% - 20px);
    height: fit-content;
  }
`;

const CardImage = styled.img`
  width: 50%;
  height: 100%;
  object-fit: cover;
  // max-width: 800px
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  text-align: center;
  width: 50%;
  font-size: 1.2em;
`;
const CardTitle = styled.p`
  font-size: 2.5em;
  color: ${({ theme }) => theme.cardColor};
  // font-weight: bold;
  font-family: 'Lora', serif;
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.color};
  color: ${({ theme }) => theme.buttonColor};
  border: none;
  padding: 20px 100px 20px 100px;
  border-radius: 50px; /* Adjust for full rounding */
  cursor: pointer;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  font-size:1.2em;
  // width:50%;

  &:hover {
    background-color: ${({ theme }) => theme.buttonHoverBackground};
  }
`;


const Pricing = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });

  // Pricing options data
  const pricingOptions = [
    {
      title: 'VegaVeta Anchor',
      priceMonthly: '$550 per month',
      priceYearly: '$5500 per year (save 20%)',
      features: [
        'Stock Prices — North America',
        'Fundamental Data — North America',
        'Commodity Data — US commodities',
        'Forex Data involving USD',
        'Cryptocurrency Data',
        'Weekly Chartbook',
        'Customizable Dashboard',
        'Chart Creation',
        'Portfolio Monitoring',
        'Portfolio Analysis (updated weekly)',
        'Index Prices — North America',
        'Economic and Socioeconomic Data',
        'Report Builder',
        'Real-time News',
        'ESG Data',
        'Sector and Industry Performance',
        'Watchlist',
        'Email/Ticketing Customer Support'
      ]
    },
    {
      title: 'VegaVeta Ascent',
      priceMonthly: '$750 per month',
      priceYearly: '$7500 per year (save 20%)',
      features: [
        'Everything in VegaVeta Anchor plus the following:',
        'Global Stock Prices*',
        'Global Fundamental Data*',
        'Global Political Data',
        'Global Economic and Socioeconomic Data',
        'Global/Region Analysis',
        'Global Index Analysis',
        'Global Sector and Industry In-depth Analysis',
        'VegaVeta LLM Reporting',
        'VegaVeta Portolio Optimizer',
        'Advanced Portfolio Analysis',
        'Weekly Chartbook (personalized with your logo)',
        'Organization Creation',
        'Client Portal',
        'Organization/Client Chat',
        'Auto Report Email',
        'Mutual Funds*',
        'ETF*',
        'Email/Ticketing Customer Support Priority'
      ]
    },
    {
      title: 'VegaVeta Apex',
      priceMonthly: 'Coming Soon',
      priceYearly: '',
      features: [
        'Everything in VegaVeta Ascent plus the following:',
        'VegaVeta QuinBridge Dynamic Model',
        'VegaVeta ML Securities Recommendation',
        'VegaVeta Watchdog',
        'VetaVeta AutoNav',
        'VegaVeta ML Forecasting',
        'VegaVeta Lab',
        'Dedicated Customer Success Manager'
      ]
    },
    {
      title: 'VegaVeta Enterprise',
      priceMonthly: 'Contact Us',
      priceYearly: '',
      features: [
        'Customizable Solutions',
        'Enhanced Security',
        'Dedicated Support',
        'Scalability',
        'Collaboration Tools'
      ]
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header />

        <Card>
            <CardContent>
              <CardTitle>See what plan works best <br/>for you</CardTitle>
              <StyledButton>Request a free consultation</StyledButton>
              {/* <p>Access real-time and historical data on thousands of stocks, ETFs, and indices. Our robust analysis helps you make informed investment decisions, keeping you ahead of market trends.</p> */}
            </CardContent>
            <CardImage src={headerImage} alt="Image 1" />
            
          </Card>
          
        <PricingContainer>
          {pricingOptions.map((option, index) => (
            <PricingCard key={index}>
              <PricingHeader>
                <Title>{option.title}</Title>
                
              </PricingHeader>
              <hr /><br/><br/>
              {option.priceYearly && <Discount>{option.priceYearly}</Discount>}
              <Price>{option.priceMonthly}</Price>
              {/* <Button>Configure Your Server</Button> */}
              <br/>
              <FeaturesList>
                {option.features.map((feature, idx) => (
                  <FeatureItem key={idx}>
                    <i className="fas fa-check"></i> {feature}
                  </FeatureItem>
                ))}
              </FeaturesList>
            </PricingCard>
          ))}
        </PricingContainer>
      </Container>        <Footer />

    </ThemeProvider>
  );
};

export default Pricing;
