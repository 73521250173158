import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { supabase } from '../../supabaseClient';
import { useAuth } from '@clerk/clerk-react';
import { FaTrashAlt } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';

const ParentContainer = styled.div`
  width: 100%;
  height: 100%;
    display: flex;
  overflow: auto;
`;
const Table = styled.table`
  width: 100%;               // Make sure the table uses the full width of its parent container
position: relative;  
//   height: 80%;              // Table will take up full height of its parent container
  overflow: auto;            // Enable scrolling for overflowing content
        // Ensures the table position is relative for any child absolute positioning
  border-collapse: collapse;
//   margin-top:30%;

  // Optional: Add padding to improve appearance, e.g., padding inside cells
  th, td {
    padding: 10px;
    border: 1px solid #ccc;
  }
`;



const TableHeader = styled.th`
  border: 1px solid ${({ theme }) => theme.border};
  padding: 8px;
  text-align: left;
  background: #AC544C;  
`;

const TableCell = styled.td`
  border: 1px solid ${({ theme }) => theme.border};
  padding: 8px;
`;

const WatchListButton = styled.button`
  float: right;
  margin-left: 10px;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.color};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  z-index: 10; /* Ensure this button is clickable */
`;


const Watchlist = (callWatchList) => {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);
  const { userId } = useAuth();

  useEffect(() => {
    const fetchWatchlist = async () => {
      try {
        // Fetch data from Supabase
        const { data: watchlistData, error: supabaseError } = await supabase
          .from('market_watchlist')
          .select('name, symbol')
          .eq('user_id', userId);

        if (supabaseError) {
          throw supabaseError;
        }

        // Fetch adjClose prices for each symbol
        const updatedCompanies = await Promise.all(
          watchlistData.map(async (company) => {
            try {
              const response = await axios.get(
                `https://financialmodelingprep.com/api/v3/historical-price-full/${company.symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e&from=2023-01-01`
              );
              const latestData = response.historical?.[0]; // Get the most recent data
              return {
                ...company,
                adjClose: latestData ? latestData.adjClose : 'N/A',
              };
            } catch (err) {
              console.error(`Error fetching data for ${company.symbol}:`, err);
              return { ...company, adjClose: 'Error' };
            }
          })
        );

        setCompanies(updatedCompanies);
      } catch (err) {
        console.error('Error fetching watchlist:', err);
        setError('Failed to load watchlist');
      }
    };

    fetchWatchlist();
  }, [userId]);

  const deleteFromWatchlist = async (symbol) => {

    console.log(symbol);
    
    const { data, error } = await supabase
      .from('market_watchlist') // The table name
      .delete() // Perform delete
      .eq('user_id', userId) // Ensure it matches the correct user
      .eq('symbol', symbol); // Ensure it matches the correct symbol
  
    if (error) {
      // Handle error, maybe show a toast or log
      toast.error('Error removing from watchlist');
    } else {
      // Success
      toast.success('Successfully removed from watchlist!');
    }
  };
  

  return (
    <>
      {error && <p>{error}</p>}
      {companies.length > 0 ? (
        <ParentContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader>Name</TableHeader>
              <TableHeader>Symbol</TableHeader>
              <TableHeader>Market Price</TableHeader>
              <TableHeader>Delete</TableHeader>
            </tr>
          </thead>
          <tbody>
            {companies.map((company, index) => (
              <tr key={index}>
                <TableCell>{company.name}</TableCell>
                <TableCell>{company.symbol}</TableCell>
                <TableCell>$ {company.adjClose}</TableCell>
                <TableCell>
                <WatchListButton 
                    onClick={() => deleteFromWatchlist(company.symbol)} 
                    style={{ background: 'transparent', border: 'none', cursor: 'pointer', zIndex:10 }}
                >
    <FaTrashAlt style={{ color: 'red', fontSize: '14px' }} />                </WatchListButton>
                </TableCell>
              </tr>
            ))}
          </tbody>
          <ToastContainer/>
        </Table>
        </ParentContainer>
      ) : (
        <p>No items in your watchlist.</p>
      )}
    </>
  );
};

export default Watchlist;
